import { useEffect, useState } from 'react';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { createTheme } from '@mui/material/styles';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import './App.css';
import { CreateCampaignPage } from './pages/CreateCampaign/CreateCampaignPage';
import { ListCampaignsPage } from './pages/ListCampaigns/ListCampaignsPage'
import { HomePage } from './pages/Home/HomePage';
import { LoginPage } from './pages/Login/LoginPage';
import { EditPromotionPage } from './pages/EditPromotion/EditPromotionPage';
import Header, { CognitoUser } from './parts/Header/Header'
import { Auth, Hub, Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { EditCampaignPage } from './pages/EditCampaignPage/EditCampaignPage';
import { AssignPromotionPage } from './pages/AssignPromotion/AssignPromotionPage';
import MUItheme from './MuiTheme'
import ProgressBar from './common/ProgressBar/ProgressBar';
import GridSkeleton from './common/Skeletons/GridSkeleton';
import { AddPrizePage } from './pages/AddPrize/AddPrizePage';
import ListCurrenciesPage from './pages/Currencies/ListCurrenciesPage';
import CurrencyConfiguratorPage from './pages/Currencies/CurrencyConfiguratorPage';
import { CurrencyPaths } from './constants/currency-constants';
import { AdminPanelPaths } from './pages/AdminPanel/Permissions/adminpanel-constants';
import { AdminPanelPage } from './pages/AdminPanel/AdminPanelPage';
import { LicenseInfo } from '@mui/x-license-pro';
import { NotAuthorizedPage } from './pages/NotAuthorizedPage/NotAuthorizedPage';

LicenseInfo.setLicenseKey('YOUR_LICENSE_KEY');

const theme = createTheme(MUItheme);
declare module '@mui/material/styles' {
    interface TypographyVariants {
        subheader: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        subheader?: React.CSSProperties;
    }
}
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        subheader: true;
    }
}

const homeUrl = `${window.location.origin}/`;
const updatedAwsConfig = {
    ...awsconfig,
    oauth: {
        ...awsconfig.oauth,
        redirectSignIn: homeUrl,
        redirectSignOut: homeUrl,
        responseType: "code",
    }
}

Amplify.configure(updatedAwsConfig);

interface AppData {
    user: CognitoUser | null,
    authLoading: Boolean,
    attemptToSignIn: Boolean,
    loginError: Boolean
}

function App() {
    const [appData, setData] = useState<AppData>({
        user: null,
        authLoading: true,
        attemptToSignIn: false,
        loginError: false
    });
    const { hash } = useLocation();
    const userEmail = appData.user && appData.user.email;

    useEffect(() => {
        const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                case 'signOut':
                case 'oAuthSignOut':
                    sessionStorage.removeItem('allowedPages')
                    sessionStorage.removeItem('willReturnFromRedirect')
                    setData({
                        user: null,
                        authLoading: true,
                        attemptToSignIn: true,
                        loginError: false
                    })
                    break;
                case 'oAuthSignIn':
                    setData({
                        user: data,
                        authLoading: false,
                        attemptToSignIn: true,
                        loginError: false
                    })
                    break;
                case 'oAuthSignIn_failed':
                    sessionStorage.setItem('willReturnFromRedirect', 'true')
                    setData({
                        user: null,
                        authLoading: true,
                        attemptToSignIn: true,
                        loginError: false
                    })
                    break;
            }
        });

        const regExp = /error_description=(?<error_location>PreTokenGeneration).+(?<error_msg>Authorization\Wfailed).+(?<cognito_group_error>Cognito\Wgroup\Wmissing)/;
        if (regExp.test(hash)) {
            const res = regExp.exec(hash);

            if (res) {
                console.error(`LogIn Error at ${res.groups?.error_location}. Error Message: ${res.groups?.error_msg}. ${res.groups?.cognito_group_error}`);

                sessionStorage.removeItem('willReturnFromRedirect');

                setData({
                    user: null,
                    authLoading: false,
                    attemptToSignIn: false,
                    loginError: true
                });

                return unsubscribe;
            }
        }

        if (!userEmail) {
            Auth.currentAuthenticatedUser()
                .then(currentUser => Auth.userSession(currentUser))
                .then((session) => {
                    const tokenPayload = session.getIdToken().payload;
                    sessionStorage.setItem('allowedPages', tokenPayload.allowedPages);

                    setData({
                        user: tokenPayload,
                        authLoading: false,
                        attemptToSignIn: true,
                        loginError: false
                    });
                })
                .catch((e) => {
                    console.error('Auth: ', e);
                    sessionStorage.removeItem('willReturnFromRedirect');
                });
        }

        return unsubscribe;
    }, [userEmail, hash]);

    if (appData.loginError) {
        return (
            <ThemeProvider theme={theme}>
                <Header auth={appData.user} />
                <NotAuthorizedPage />
            </ThemeProvider>
        )
    }

    if (appData.authLoading && appData.attemptToSignIn) {
        return (
            <ThemeProvider theme={theme}>
                <Header auth={appData.user} />
                <ProgressBar />
                <GridSkeleton items={4} />
            </ThemeProvider>
        )
    }

    return (
        <ThemeProvider theme={theme}>
            <Header auth={appData.user} />
            {
                !appData.user &&
                sessionStorage.getItem('willReturnFromRedirect') === 'true' &&
                <GridSkeleton items={4} />
            }
            <div className="bodyWrapper">
                <Routes>
                    {
                        !appData.user &&
                        !appData.attemptToSignIn &&
                        sessionStorage.getItem('willReturnFromRedirect') !== 'true' &&
                        <Route path='/' element={<LoginPage />} />
                    }
                    {appData.user && <Route path='/' element={<HomePage />} />}
                    <Route path='/home' element={
                        <PrivateRoute>
                            <HomePage />
                        </PrivateRoute>
                    } />
                    <Route path="/createCampaign" element={
                        <PrivateRoute>
                            <CreateCampaignPage />
                        </PrivateRoute>
                    } />
                    <Route
                        path="/assignPromotion/:promotionId"
                        element={
                            <PrivateRoute>
                                <AssignPromotionPage />
                            </PrivateRoute>
                        }
                    />
                    <Route path='/listCampaigns' element={
                        <PrivateRoute>
                            <ListCampaignsPage />
                        </PrivateRoute>
                    } />
                    <Route
                        path="/editCampaign/:campaignId"
                        element={
                            <PrivateRoute>
                                <EditCampaignPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/editPromotion/:promotionId"
                        element={
                            <PrivateRoute>
                                <EditPromotionPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/editPromotion/:promotionId/prizeTab"
                        element={
                            <PrivateRoute>
                                <EditPromotionPage initialTab={"prizes"} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/editPromotion/:promotionId/winningMomentsTab"
                        element={
                            <PrivateRoute>
                                <EditPromotionPage initialTab={"winningMoments"} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/addPrize/:campaignId/:configurationId"
                        element={
                            <PrivateRoute>
                                <AddPrizePage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={CurrencyPaths.ListCurrencies}
                        element={
                            <PrivateRoute>
                                <ListCurrenciesPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={CurrencyPaths.CreateCurrency}
                        element={
                            <PrivateRoute>
                                <CurrencyConfiguratorPage isEdit={false} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={CurrencyPaths.EditCurrency}
                        element={
                            <PrivateRoute>
                                <CurrencyConfiguratorPage isEdit={true} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={AdminPanelPaths.AdminPanel}
                        element={
                            <PrivateRoute>
                                <AdminPanelPage initialTab={'permissions'} />
                            </PrivateRoute>
                        }
                    />
                    <Route path='/login' element={<LoginPage />} />
                </Routes>
            </div>
        </ThemeProvider>
    );
}

export default App;
