import { Typography, Box, Button, Link } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import './NotAuthorizedPage.css';

const authRedirect = 'https://dev.azure.com/GlobalEngg/PromoPlus%20%28NGPS%29/_wiki/wikis/PromoPlus%20%28NGPS%29.wiki/13778/How-to-request-access-to-Self-Service-2-';

function NotAuthorizedPage() {
    return (
        <Box className='section'>
            <Typography variant='subheader'>You are not authorized to see this page. Please, request access <Link href={authRedirect}>here</Link>.</Typography>
            <Button className='back-button' variant="contained" color="primary" component={RouterLink} to='/'>Back to Home</Button>
        </Box>
    )
}

export { NotAuthorizedPage }
