import { Storage, API } from 'aws-amplify';
import { getPromotionsTable, getPrizesForConfig } from '../../graphql/queries';
import { useState, useEffect, useCallback } from 'react';
import { Box, Grid, Typography, Button, Backdrop, CircularProgress } from '@mui/material';
import { SubNav } from '../../common/SubNav/SubNav';
import { CopyInput } from '../../common/CopyInput/CopyInput';
import './EditPromotionPage.css';
import { useParams } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate, useLocation } from 'react-router-dom';
import { Notification } from '../../common/Notification/Notification';
import type { AlertColor } from '@mui/material';
import { EditPromotionGeneralInfo } from './GeneralInfo/EditPromotionGeneralInfo';
import { EditPromotionVouchers } from './VouchersTab/EditPromotionVouchers';
import { WinningMoments } from './WinningMoments/WinningMoments';
import { Prizes } from './Prizes/Prizes';
import { MixCodes } from './MixCodes/MixCodes';
import { Limits } from './LimitsTab/Limits';
import { AdditionalDetails } from './AdditionalDetails/AdditionalDetails';
import { Currencies } from './CurrenciesTab/Currencies';
import { GenerateWinningMoments } from './../../components/generateWinningMomentsComponent/generateWinningMoments';
import { DialogBox } from '../../common/Dialog/DialogBox';
import { notificationType } from '../../types/notifications';
import { PromoMechanic, PromoVariation } from '../../types/componentTypes/editPromotion';
import { AllocationRules } from './AllocationRules/AllocationRules';
import { AddAllocationRulesPage } from '../AddAllocationRules/AddAllocationRulePage';
import { allocationRules } from '../../types/componentTypes/allocationRules';
import { usePrompt } from '../../common/PromptOnChanges/promptHelper';
import { ConfigType } from '../../types/configuration';
import FlowTabButtons from '../../common/FlowTabButtons/FlowTabButtons';
import { ParticipationExport } from './ParticipationExport/ParticipationExport';
import { Mechanic } from './AdditionalDetails/types/AdditionalDetailsTypes';
import { AdditionalDetailsHelpers } from './AdditionalDetails/utils/AdditionalDetailsHelpers';
import { FriendReferral } from './FriendReferral/FriendReferral';
import { getConfig } from '../../utils/s3FileUtils';

Storage.configure({
    customPrefix: {
        public: '',
        protected: '',
        private: '',
    },
});
interface winningMomentsGeneratorProps {
    showScreen: boolean;
    prizeParams: any[];
}

interface inputProps {
    initialTab?: string;
}

let defaultCampaignState: any = {};

export interface prizeDataType {
    priority: number;
    name: string;
    tier: number;
    prize_id: string;
    active: boolean;
    configuration_id: string;
    pool_prize?: boolean;
    winning_ratio?: number;
}

const FlowNames: { [key: string]: string } = {
    instantWin: 'instantWin',
    redeemPincodeForCurrencies: 'redeemPincodeForCurrencies',
    promoEntry: 'promoEntry',
    autoRedeemCnG: 'autoRedeemCnG',
};

const EditPromotionPage = ({ initialTab }: inputProps) => {
    const [config, setConfig] = useState<ConfigType>({ promotionId: '', configurationId: '', flow: {} });
    const [prizes, setPrizes] = useState<Array<prizeDataType>>([]);
    const [stateCampaignData, setStateCampaignData] = useState(defaultCampaignState);
    const [promotionName, setPromotionName] = useState('');
    const [promotionMechanic, setPromotionMechanic] = useState<Mechanic>();
    const [promotionFlow, setPromotionFlow] = useState('');
    const [promotionVariation, setPromotionVariation] = useState('');
    const [showWinningMomentsGeneratorScreen, setShowWinningMomentsGeneratorScreen] =
        useState<winningMomentsGeneratorProps>({ showScreen: false, prizeParams: [] });
    const [showAllocationRulePage, setShowAllocationRulePage] = useState<{
        showScreen: boolean;
        state?: allocationRules;
    }>({ showScreen: false });
    const { state } = useLocation();
    const [tab, setTab] = useState(() => sessionStorage.getItem('selectedTab') || initialTab || state?.tab || 'generalInfo');
    const [loading, setLoading] = useState(true);
    const [notificationState, setNotificationState] = useState<notificationType>({
        open: false,
        title: '',
        content: '',
        level: 'success' as AlertColor,
    });
    const [changesDetected, setChangesDetected] = useState(false);
    const [modal, setOpenModal] = useState(false);
    const [tabTarget, setTabTarget] = useState('');

    let navigate = useNavigate();
    let urlParams = useParams();
    const fileName = `${urlParams.promotionId}/conf.txt`;

    const returnToCampaign = () => {
        navigate(`/editCampaign/${config.promotionId}`);
        AdditionalDetailsHelpers.deleteInstance();
    };

    const setPromoAttributes = useCallback((config: ConfigType) => {
        setPromotionName(config?.configurationParameters?.additionalInformation?.name || config.promotionId);
        const {
            promoDetails,
            promoSubMechanic,
            flows: [flow],
        } = extractPromoAttributes({
            flowNames: Object.keys(config?.flow),
            configurationSubMechanic: config?.configurationParameters?.configurationSubMechanic,
            config,
        });
        setPromotionMechanic(promoDetails[flow].promoMechanic as Mechanic);
        setPromotionFlow(flow);
        setPromotionVariation(
            promoSubMechanic || promoDetails[flow].promotionVariation || promoDetails[flow].promoMechanic
        );
    }, []);

    usePrompt('This will discard all the changes. Are you sure?', changesDetected);

    const extractPromoAttributes = ({
        flowNames,
        config,
        configurationSubMechanic,
    }: {
        flowNames: string[];
        config: Partial<ConfigType>;
        configurationSubMechanic?: string;
    }) => {
        let isIWPromo = false;
        const promoSubMechanic = configurationSubMechanic ? configurationSubMechanic : '';
        const flows: string[] = [];

        const promoDetails = flowNames.reduce(
            (promoObj: { [key: string]: { promoMechanic: string; promotionVariation: string } }, flow: string) => {
                if (!config.flow) {
                    return promoObj;
                }

                const flowName = FlowNames[flow];
                if (!flowName) {
                    return promoObj;
                }

                flows.push(flowName);
                const { flowLambdas, params } = config.flow[flowName];

                if (flowName === FlowNames.redeemPincodeForCurrencies) {
                    return {
                        ...promoObj,
                        [flowName]: {
                            promoMechanic: PromoMechanic.CollectAndGet,
                            promotionVariation: flowLambdas.includes('autoRedeemPrizeLambda')
                                ? PromoVariation.CollectAndGetAuto
                                : PromoMechanic.CollectAndGet,
                        },
                    };
                }

                if (flowName === FlowNames.instantWin) {
                    isIWPromo = true;
                    return {
                        ...promoObj,
                        [flowName]: {
                            promoMechanic: PromoMechanic.InstantWin,
                            promotionVariation:
                                !params?.alwaysWin && params?.algorithm ? PromoVariation.InstantWinWM : '',
                        },
                    };
                }

                if (flowName === FlowNames.promoEntry) {
                    return {
                        ...promoObj,
                        [flowName]: {
                            promoMechanic: PromoMechanic.PromoEntry,
                            promotionVariation: PromoMechanic.PromoEntry,
                        },
                    };
                }

                if (flowName === FlowNames.autoRedeemCnG) {
                    return {
                        ...promoObj,
                        [flowName]: {
                            promoMechanic: PromoMechanic.AutoRedeemCnG,
                            promotionVariation: PromoMechanic.AutoRedeemCnG,
                        },
                    };
                }

                return promoObj;
            },
            {}
        );
        return {
            promoDetails,
            isIWPromo,
            promoSubMechanic,
            flows,
        };
    };

    useEffect(() => {
        const getPrizes = async () => {
            const queryResult: any = await API.graphql({
                query: getPrizesForConfig,
                variables: { configuration_id: urlParams.promotionId },
            });
            const prizesForConfig = queryResult?.data?.getPrizesForConfig?.items || [];
            setPrizes(prizesForConfig);
        };
        try {
            getPrizes();
        } catch (e) {
            setNotificationState({
                open: true,
                title: 'Loading prizes request failed!',
                content: ``,
                level: 'error',
            });
            console.error('Loading prizes failed with: ', e);
        }
    }, [urlParams.promotionId]);

    useEffect(() => {
        const retrieveCampaign = async (insertedCampaignId: string) => {
            try {
                const queryResult: any = await API.graphql({
                    query: getPromotionsTable,
                    variables: { promotion_id: insertedCampaignId },
                });
                const campaignData = queryResult.data.getPromotionsTable;
                return campaignData;
            } catch (e) {
                setNotificationState({
                    open: true,
                    title: 'Error',
                    content: 'Campaign not found!',
                    level: 'error',
                });
                console.error('Retrieval of config failed with: ', e);
            }
        };

        const retrieveConfig = async () => {
            setLoading(true);

            try {
                const config = await getConfig(fileName);
                setConfig(config);
                setPromoAttributes(config);
                let campaignResult = await retrieveCampaign(config.promotionId);
                setStateCampaignData(campaignResult);
            } catch (e) {
                setNotificationState({
                    open: true,
                    title: 'Error',
                    content: 'Promotion ID not found!',
                    level: 'error',
                });
                console.error('Retrieval of config failed with: ', e);
            }
        };

        retrieveConfig()
            .then((config) => {
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                setNotificationState({
                    open: true,
                    title: 'Config not found',
                    content: 'Supplied config id does not exist!',
                    level: 'error',
                });
                console.error('Retrieval of config/emailTemplates failed', e);
            });
    }, [fileName, tab, setPromoAttributes]);

    const linksMapper = [
        { name: 'Campaigns', href: `/listCampaigns` },
        {
            name: `${stateCampaignData?.promotion_name || 'Name cannot be retrieved'}`,
            href: `/editCampaign/${config.promotionId}`,
        },
        { name: `${promotionName}` },
    ];

    const handleConfirmationWindow = (target: string) => {
        setOpenModal(true);
        setTabTarget(target);
    };

    useEffect(() => {
        sessionStorage.removeItem('selectedTab');

        sessionStorage.setItem('selectedTab', tab);

        return () => {
            sessionStorage.removeItem('selectedTab');
        };
    }, [tab]);

    const handleChangeTabConfirm = () => {
        setTab(tabTarget);
        setOpenModal(false);
        setChangesDetected(false);
    };

    return (
        <>
            <DialogBox
                isOpen={modal}
                actionButtonLabels={['Close', 'Discard']}
                actionButtonHandlers={[() => setOpenModal(false), handleChangeTabConfirm]}
                dialogCloseHandler={() => setOpenModal(false)}
                dialogContent={'Are you sure that you want to change tab? Changes will be discarded.'}
            />
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color='inherit' />
            </Backdrop>
            <Notification notificationState={notificationState} setNotificationState={setNotificationState} />
            {!showWinningMomentsGeneratorScreen.showScreen && !showAllocationRulePage.showScreen && (
                <div className='pageWrap'>
                    <Box className='pageHeader'>
                        <Box sx={{ marginBottom: 2 }}>
                            <SubNav linksMapper={linksMapper} textVariant='overline' linkClass='subnav-edit-promo' />
                        </Box>
                        <Grid container spacing={2} alignItems='center'>
                            <Grid item md={6}>
                                <Typography variant='subheader'>{promotionName}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} sx={{ marginTop: 2 }}>
                            <Grid item md={3}>
                                <Typography variant='body1' className='mechanicHeader'>
                                    {promotionMechanic}
                                </Typography>
                                <Button
                                    className='BackBtn'
                                    variant='outlined'
                                    onClick={returnToCampaign}
                                    startIcon={<KeyboardArrowLeftIcon fontSize='small' />}>
                                    Back to Campaign
                                </Button>
                            </Grid>
                            <Grid item md={6}>
                                <CopyInput
                                    inputLabel='Promotion Id'
                                    inputName='promotionID'
                                    inputValue={config.configurationId}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box className='pageContent'>
                        <Grid container columnSpacing={6}>
                            <Grid item xs={2.3} className='leftContent'>
                                <Box className='leftNav'>
                                    <FlowTabButtons
                                        promotionMechanic={promotionMechanic}
                                        changesDetected={changesDetected}
                                        handleConfirmationWindow={handleConfirmationWindow}
                                        tab={tab}
                                        setTab={setTab}
                                        prizes={prizes}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={9.7}>
                                {!loading && (
                                    <>
                                        {tab === 'generalInfo' && Object.keys(stateCampaignData).length > 0 && (
                                            <EditPromotionGeneralInfo
                                                campaignData={stateCampaignData}
                                                config={config}
                                                promotionMechanic={promotionMechanic}
                                                promotionVariation={promotionVariation}
                                                setNotificationState={setNotificationState}
                                                setLoading={setLoading}
                                                handleTabChange={setChangesDetected}
                                                hasPrizes={!!prizes.length}
                                            />
                                        )}
                                        {tab === 'vouchers' && <EditPromotionVouchers config={config} />}
                                        {tab === 'prizes' && (
                                            <Prizes config={config} prizesData={prizes} setPrizeData={setPrizes} />
                                        )}
                                        {tab === 'winningMoments' && (
                                            <WinningMoments
                                                config={config}
                                                showGeneratorScreen={setShowWinningMomentsGeneratorScreen}
                                            />
                                        )}
                                        {tab === 'limits' && (
                                            <Limits
                                                promotionMechanic={promotionMechanic}
                                                config={config}
                                                setConfig={setConfig}
                                                setNotificationState={setNotificationState}
                                                handleTabChange={setChangesDetected}
                                                prizesData={prizes}
                                                setPrizeData={setPrizes}
                                            />
                                        )}
                                        {tab === 'mixcodes' && (
                                            <MixCodes
                                                config={config}
                                                setNotificationState={setNotificationState}
                                                handleTabChange={setChangesDetected}
                                            />
                                        )}
                                        {tab === 'additionalDetails' && (
                                            <AdditionalDetails
                                                config={config}
                                                setNotificationState={setNotificationState}
                                                handleTabChange={setChangesDetected}
                                                mechanic={promotionMechanic}
                                                promoFlow={promotionFlow}
                                                urlParams={urlParams}
                                            />
                                        )}
                                        {tab === 'currencies' && (
                                            <Currencies
                                                setNotificationState={setNotificationState}
                                                handleTabChange={setChangesDetected}
                                                mechanic={promotionMechanic}
                                            />
                                        )}
                                        {tab === 'allocationRules' && (
                                            <AllocationRules
                                                config={config}
                                                setShowAllocationRulePage={setShowAllocationRulePage}
                                            />
                                        )}
                                        {tab === 'referralRewards' && (
                                            <FriendReferral
                                                config={config}
                                            />
                                        )}
                                        {tab === 'participationExport' && <ParticipationExport config={config} />}
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            )}
            {showWinningMomentsGeneratorScreen.showScreen && (
                <GenerateWinningMoments
                    configurationId={config.configurationId}
                    prizeInput={showWinningMomentsGeneratorScreen.prizeParams}
                    showGeneratorScreen={setShowWinningMomentsGeneratorScreen}
                />
            )}
            {showAllocationRulePage.showScreen && (
                <AddAllocationRulesPage
                    configurationId={config.configurationId}
                    configCurrencies={config.configurationParameters?.currencies}
                    state={showAllocationRulePage?.state}
                    setShowAllocationRulePage={setShowAllocationRulePage}
                />
            )}
        </>
    );
};

export { EditPromotionPage };
